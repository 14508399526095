<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>상담내역조회</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form" >
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="290px">
                    <col width="95px">
                    <col width="90px">
                    <col width="75px">
                    <col width="135px">
                    <col width="75px">
                    <col width="110px">
                    <col width="65px">
                    <col width="130px">
                    <col width="120px">
                    <col width="120px">
                    <col width="30px">
                    <col width="65px">
                    <col width="150px">
                    <col>
                </colgroup>
                <tr>
                    <th><label class="is-required">상담일자</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                  maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                              </v-menu>
                              <span class="jh-unit">~</span>                       
                              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                              </v-menu>
                        </div>
                    </td>
                    <th><label>Contact경로</label></th>
                    <td><v-select class="jh-form" v-model="cnslInfo.CONTT_CD" :items="mixin_common_code_get(this.common_code, 'CS41', '전체')"></v-select></td>
                    <th><label>채널</label></th>
                    <td><v-select class="jh-form" v-model="cnslInfo.CHNN_CD" :items="mixin_common_code_get(this.common_code, 'CS01', '전체')"></v-select></td>
                    <th><label>발신번호</label></th>
                    <td><input type="text" class="jh-form" @keyup.enter="getList()" v-model="cnslInfo.SEND_MAN_NO" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');"></td>
                    <th><label>상담사</label></th>
                    <td><v-select class="jh-form" v-model="ATTR_A" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" @change="changeDept(ATTR_A)" style="width:130px"></v-select></td>
                    <td><v-select class="jh-form" v-model="ATTR_B" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" @change="changeUser(ATTR_A, ATTR_B)"></v-select></td>
                    <td><v-select class="jh-form" v-model="CNSL_ID" item-value="USER_ID" item-text="USER_NM" :items="CNSL_COMBO"></v-select></td>
                    <td><v-btn class="jh-btn is-fill has-icon-only" @click="showDialog('P101801')"><i class="jh-icon-search-sm is-white"></i></v-btn></td>
                    <th><label>키워드</label></th>
                    <td><input type="text" class="jh-form" @keyup.enter="getList()" v-model="cnslInfo.KEY_WORD"></td>
                </tr>
                <tr>
                    <th><label>상담유형</label></th>
                    <td>
                        <div class="jh-cols">
                            <v-select class="jh-form"
                                 v-model="cnslInfo.CNSL_TY_LCLS" 
                                 :items="CNSL_TY_CODE_1"
                                 item-text="CD_NM"
                                 item-value="CD"
                                 no-data-text="조회된 값이 없습니다"
                                 @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_LCLS, '1')"
                              ></v-select>
                            <v-select class="jh-form" 
                              v-model="cnslInfo.CNSL_TY_MDCLS" 
                              :items="CNSL_TY_CODE_2"
                              item-text="CD_NM"
                              item-value="CD"
                              no-data-text="조회된 값이 없습니다"
                              @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_MDCLS, '2')"
                          ></v-select>
                        </div>
                    </td>
                    <td colspan="2"><v-select class="jh-form"
                        v-model="cnslInfo.CNSL_TY_SCLS" 
                        :items="CNSL_TY_CODE_3"
                        item-text="CD_NM"
                        item-value="CD"
                        no-data-text="조회된 값이 없습니다"
                    ></v-select></v-select></td>
                    <th><label>고객등급</label></th>
                    <td><v-select class="jh-form" v-model="cnslInfo.CUS_LEVEL_CD" :items="mixin_common_code_get(this.common_code, 'DS38', '전체')" @change="fnLvlChng"></v-select></td>
                    <th><label>고객번호</label></th>
                    <td><input type="text" class="jh-form" @keyup.enter="getList()" v-model="cnslInfo.CLNT_NO"></td>
                    <th><label>고객명</label></th>
                    <td><input type="text" class="jh-form" @keyup.enter="getList()" v-model="cnslInfo.CUST_NM"></td>
                    <td class="is-jh-20">
                        <div class="jh-cols">
                            <input type="number" class="jh-form" v-model="cnslInfo.CALL_TIME" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                            <span class="jh-unit">분 이상</span>
                        </div>
                    </td>
                    <th colspan="3"><label>ISSUE</label></th>
                    <td><input type="text" class="jh-form" @keyup.enter="getList()" v-model="cnslInfo.ISSUE"></td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="getList" :loading="isLoading">조회</v-btn></td>
                    <!--td class="has-search"><v-btn class="jh-btn is-search" @click="callApiTest">조회3</v-btn></td-->
                </tr>
            </table>
        </div>
        <!--//조회-->
        <!--리스트-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>상담이력 리스트</h2>
                <div class="is-right">
                    <!-- <v-btn class="jh-btn is-main" @click="openSave">수동저장</v-btn> -->
                    <!-- <v-btn class="jh-btn is-light" v-show="excelBtn"  @click="selectCnslHstExcel"   :loading="isLoading2">엑셀 조회</v-btn> -->
                    <v-btn class="jh-btn is-light" v-show="excelBtn"  @click="downloadExcel('cnslHistExcelTable', '상담이력조회', '상담이력목록')"  >엑셀다운로드</v-btn>
                    <!-- <v-btn class="jh-btn is-light" v-show="excelBtn"  @click="downloadExcel('cnslHistExcelTable', '상담이력조회', '상담이력목록')">엑셀다운로드</v-btn> -->
                </div>
            </div>
            <div class="jh-form-wrap" v-show="false">
                <data-tables
                    ref="excelDataTable"
                    id="cnslHistExcelTable"
                    dataTableClass="has-control"
                    :data-table-options="dataTableOptionsExcel"
                    @click:row="onClickRow"
                    @dblclick:row="onDblClickRow"
                    :paginationOptions="paginationExcelOptions"
                    >
                    <template v-slot:item.RDWT_ID="{ item, index }">
                        <button type="button" class="jh-btn is-icon" v-if="item.CONTT_CD == '01' && !mixin_isEmpty(item.RDWT_ID)" @click.stop="openRdwtWebPlay(item)"><i class="jh-icon-circle-play is-blue"></i></button>
                        <button type="button" class="jh-btn is-icon" v-else-if="item.CONTT_CD == '02'" title="대화내용보기" @click.stop="openP101102(item)"><i class="jh-icon-comment is-blue"></i></button>
                    </template>                
                </data-tables>         
            </div>         
            <data-tables
                ref="dataTable"
                id="cnslHistTable"
                dataTableClass="has-control"
                :data-table-options="dataTableOptions"
                @click:row="onClickRow"
                @dblclick:row="onDblClickRow"
                :paginationOptions="paginationOptions"
                >
                <template v-slot:item.RDWT_ID="{ item, index }">
                    <button type="button" class="jh-btn is-icon" v-if="item.CONTT_CD == '01' && !mixin_isEmpty(item.RDWT_ID)" @click.stop="openRdwtWebPlay(item)"><i class="jh-icon-circle-play is-blue"></i></button>
                    <button type="button" class="jh-btn is-icon" v-else-if="item.CONTT_CD == '02'" title="대화내용보기" @click.stop="openP101102(item)"><i class="jh-icon-comment is-blue"></i></button>
                </template>                
            </data-tables>
      
        </div>
        <!--//리스트-->
        <!--상세-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>상담이력 상세정보</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-main" :loading="isLoading" @click="saveCnslHist()">저장</v-btn>
                </div>
            </div>
            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="90px">
                    <col width="120px">
                    <col width="95px">
                    <col width="120px">
                    <col width="90px">
                    <col width="120px">
                    <col width="90px">
                    <col width="120px">
                    <col width="90px">
                    <col width="180px">
                    <col width="90px">
                    <col>
                </colgroup>
                <tbody>
                    <tr>
                        <th><label>Contact일자</label></th>
                        <td><span class="is-value">{{cnslDtl.CNSL_BDATE}}</span></td>
                        <th><label>인입대표번호</label></th>
                        <td><span class="is-value">{{mixin_newPhoneFormat(cnslDtl.IVR_IN_NO)}}</span></td>
                        <th><label>인입경로</label></th>
                        <td colspan="3"><span class="is-value">{{cnslDtl.IVR_IN_PATH}}</span></td>
                        <th rowspan="3"><label>상담유형</label></th>
                        <td>
                            <v-select class="jh-form"
                            v-model="cnslDtl.CNSL_TY_LCLS" 
                            :items="CNSL_TY_CODE_11"
                            item-text="CD_NM"
                            item-value="CD"
                            no-data-text="조회된 값이 없습니다"
                            @change="changeCounselDetailTypeKey(ASP_CUST_KEY, cnslDtl.CNSL_TY_LCLS, '3')"></v-select>
                        </td>
                        <th rowspan="3"><label>상담내용</label></th>
                        <td colspan="3" rowspan="3"> 
                            <textarea class="jh-form" style="height: 85px;" v-model="cnslDtl.CNSL_CNTN"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <!-- <th><label>상담종료일자</label></th>
                        <td><span class="is-value">{{mixin_dateFormat(cnslDtl.CNSL_EDATE)}}</span></td> -->
                        <!-- <th><label>고객등급</label></th>
                        <td> <span class="is-value" >{{cnslDtl.CUST_LEV}}</span></td>
                        <th><label>고객번호</label></th>
                        <td><span class="is-value" >{{cnslDtl.CUST_NO}}</span> </td> -->
                        <th><label>상담시작시간</label></th>
                        <td><span class="is-value">{{cnslDtl.CNSL_BEGIN_TM}}</span></td>
                        <th><label>발신/수신번호</label></th>
                        <td><span class="is-value" >{{cnslDtl.SEND_MAN_NO}}</span></td>
                        <th><label>계좌번호</label></th>
                        <td><span class="is-value" >{{cnslDtl.ACCT_NO}}</span></td>
                        <th><label>고객명</label></th>
                        <td> <span class="is-value">{{cnslDtl.CUST_NM}}</span> </td>
                        <td>
                            <v-select class="jh-form" 
                            v-model="cnslDtl.CNSL_TY_MDCLS" 
                            :items="CNSL_TY_CODE_22"
                            item-text="CD_NM"
                            item-value="CD"
                            no-data-text="조회된 값이 없습니다"
                            @change="changeCounselDetailTypeKey(ASP_CUST_KEY, cnslDtl.CNSL_TY_MDCLS, '4')"></v-select>
                        </td>
                    </tr>
                    <tr>
                        <th><label>상담종료시간</label></th>
                        <td><span class="is-value">{{cnslDtl.CNSL_END_TM}}</span></td>
                        <th><label>상담사</label></th>
                        <td><span class="is-value">{{cnslDtl.CNSL_NM}}</span></td>
                        <th><label>통화결과</label></th>
                        <td><span class="is-value">{{cnslDtl.TEL_RSLT_CNM}}</span></td>
                        <th><label>고객반응</label></th>
                        <td><span class="is-value">{{cnslDtl.CUST_RECP_NM}}</span></td>
                        <td>
                            <v-select class="jh-form is-mr-15"
                                    v-model="cnslDtl.CNSL_TY_SCLS" 
                                    :items="CNSL_TY_CODE_32"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    no-data-text="조회된 값이 없습니다"
                            ></v-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--상세-->
        <v-dialog max-width="1000" v-model="dialogP101801">
            <dialog-P101801
            @hide="hideDialog('P101801')"
            :param="param"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('P101801'), setUserData()]"
            ></dialog-P101801>
            <template v-slot:body></template>
        </v-dialog>
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import { apiCall } from "@/store/callUtil.js";
import { eventBus } from "@/store/eventBus.js";
import dataTables from "@/components/DataTables";
import dialogP101801 from "./P101801.vue";
import { mapGetters } from "vuex"
import apiChat from "../../store/apiChatParamUtil.js";

// const RDWP_SERVER_URL1 = 'https://voicerec.ebestsec.co.kr:18088/provider/key/play';


export default {
    name: "MENU_E010201", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dialogP101801,
        dataTables
    },
    data() {
        return {
            dialogP101801: false,
            menuStartDate: false,
            menuEndDate: false,        
            isLoading: false,
            isLoading2: false,
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],

            CURR_DT : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            ASP_CUST_KEY: '001',
            userInfo: {},
            cnslInfo: {}, //상담 정보 객체
            cnslDtl: {
                CNSL_HIST_NO: "",
                CNSL_TY_LCLS: "",
                CNSL_TY_MDCLS: "",
                CNSL_TY_SCLS: "",
                CNSL_CNTN: ""
            },  //상담 상세 정보 객체

            formData:{
                USER_ID : "",
                USER_NM : "",
                USER_NICK : "",
                USER_STATUS_CD : "",
                USER_ATTR_A : [],
                USER_ATTR_B : [],
                ASP_NEWCUST_KEY: "001",
            },
            param: {
                page: 'E010201',
                type: ''
            },
            ATTR_A: "",
            ATTR_B: "",
            CNSL_ID: this.$store.getters["userStore/GE_USER_ID"],
            MOD_YN: false,  // 수정여부
            excelBtn: false,  // 수정여부

            cdCRS000: [],
            codeDS38: [],

            preDate: [],
            gridCnslhistItems: [],
            gridCnslhistExcelItems: [],

            CNSL_TY_CODE_1: [], // 상담유형(대)
            CNSL_TY_CODE_2: [], // 상담유형(중)
            CNSL_TY_CODE_3: [], // 상담유형(소)

            CNSL_TY_CODE_11: [], // 상담유형(중)
            CNSL_TY_CODE_22: [], // 상담유형(중)
            CNSL_TY_CODE_32: [], // 상담유형(소)

            DEPT_COMBO: [], // 부서콤보
            TEAM_COMBO: [], // 팀콤보
            CNSL_COMBO: [], // 상담원콤보

            common_code: [], //공통코드
            selectedRow: [], //선택된 로우

            headers: [
                { text: 'NO', value: 'ROWNUM', align: 'center', width: '60px', sortable: false },
                { text: '상담일자', value: 'CNSL_BDATE', align: 'center', width: '80px', },
                { text: '시작시간', value: 'CNSL_BEGIN_TM', align: 'center', width: '70px', sortable: false },
                { text: '종료시간', value: 'CNSL_END_TM', align: 'center', width: '70px', sortable: false },
                { text: '소요시간', value: 'CNSL_TM', align: 'center', width: '70px', sortable: false },
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', },
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '80px', },
                { text: '계좌번호', value: 'ACCT_NO', align: 'center', width: '110px', sortable: false },
                { text: '발신번호', value: 'SEND_MAN_NO', align: 'center', width: '130px', sortable: false },
                { text: '상담유형(대)', value: 'CNSL_TY_LCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담유형(중)', value: 'CNSL_TY_MDCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담유형(소)', value: 'CNSL_TY_SCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담사명/사번', value: 'CNSL_NM', align: 'center', width: '95px', },
                { text: '고객등급', value: 'CUST_LEV', align: 'center', width: '110px', },
                { text: 'ISSUE', value: 'ISSUE', align: 'center', width: '90px', },
                { text: '청취', value: 'RDWT_ID', align: 'center', width: '40px', sortable: false },
                { text: 'Contact경로', value: 'CNTC_PATH', align: 'center', width: '90px', },
                { text: '채널', value: 'CHANNER', align: 'center', width: '60px', },
            ],
            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '410px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,
                singleExpand: false,
                load: undefined,
            },

            headersExcel: [
                { text: 'NO', value: 'ROWNUM', align: 'center', width: '60px', sortable: false },
                { text: '상담일자', value: 'CNSL_BDATE', align: 'center', width: '80px', },
                { text: '시작시간', value: 'CNSL_BEGIN_TM', align: 'center', width: '70px', sortable: false },
                { text: '종료시간', value: 'CNSL_END_TM', align: 'center', width: '70px', sortable: false },
                { text: '소요시간', value: 'CNSL_TM', align: 'center', width: '70px', sortable: false },
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', },
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '80px', },
                { text: '계좌번호', value: 'ACCT_NO', align: 'center', width: '110px', sortable: false },
                { text: '발신번호', value: 'SEND_MAN_NO', align: 'center', width: '130px', sortable: false },
                { text: '상담유형(대)', value: 'CNSL_TY_LCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담유형(중)', value: 'CNSL_TY_MDCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담유형(소)', value: 'CNSL_TY_SCLS_NM', align: 'left', width: '160px', sortable: false },
                { text: '상담사명/사번', value: 'CNSL_NM', align: 'center', width: '95px', },
                { text: '고객등급', value: 'CUST_LEV', align: 'center', width: '110px', },
                { text: 'ISSUE', value: 'ISSUE', align: 'center', width: '90px', },
                { text: '청취', value: 'RDWT_ID', align: 'center', width: '40px', sortable: false },
                { text: 'Contact경로', value: 'CNTC_PATH', align: 'center', width: '90px', },
                { text: '채널', value: 'CHANNER', align: 'center', width: '60px', },
                { text: '상담내용', value: 'CNSL_CNTN', align: 'center', width: '60px', },
            ],
            dataTableOptionsExcel: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '410px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 1000000,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,
                singleExpand: false,
                load: undefined,
            },
            
            

            paginationOptions: {
                totalVisible: 10
            },
            paginationExcelOptions: {
                totalVisible: 10
            },            
        }
    },
    computed: {
        ...mapGetters({
            geCustInfo: 'callStore/GE_CUST_INFO'
           ,geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN'
           ,geUserRole: 'userStore/GE_USER_ROLE'
           ,geUserAttrA: 'userStore/GE_USER_ATTR_A'
           ,geCallStt: 'callStore/GE_CALL_STATE'
        }),
        
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },
    },
    created() { 
        this.dataTableOptions.headers = this.headers;
        this.dataTableOptionsExcel.headers = this.headersExcel;

        this.DEPT_COMBO = [{CD: null, CD_NM: '전체'}];
        this.TEAM_COMBO = [{CD: null, CD_NM: '전체'}];
        this.CNSL_COMBO = [{USER_ID: null, USER_NM: '전체'}];

        this.cnslInfo.DEPT_CD = '';
        this.cnslInfo.TEAM_CD = '';
        this.cnslInfo.CNSL_USID = '';

        this.getAttrList();
    },
    async getAttrList() {
      this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
    },
    mounted() {
        if(this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N'){
            this.excelBtn = false
        }else{
            this.excelBtn = true
        }
        
        this.ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ASP_CUST_KEY"];
        this.USER_ID = this.$store.getters["userStore/GE_USER_ID"];
        this.USER_NM = this.$store.getters["userStore/GE_USER_NAME"];
        this.cnslInfo.CLNT_NO = this.geCustInfo.CLNT_NO
        this.cnslInfo.CUST_NM = this.geCustInfo.CNM

        this.callCommonCode();
        //this.initData();
        //this.getAttrList();
    },
    watch: {
        cnslDtl: function(nVal, oVal) {
            let modYn = false;

            if(!this.mixin_isEmpty(nVal.CNSL_HIST_NO)) {
                if(nVal.CNSL_HIST_NO == oVal.CNSL_HIST_NO) {
                    if(nVal.CNSL_TY_LCLS != oVal.CNSL_TY_LCLS) modYn = true;
                    if(nVal.CNSL_TY_MDCLS != oVal.CNSL_TY_MDCLS) modYn = true;
                    if(nVal.CNSL_TY_SCLS != oVal.CNSL_TY_SCLS) modYn = true;
                    if(nVal.CNSL_CNTN != oVal.CNSL_CNTN) modYn = true;

                    if(modYn) this.MOD_YN = true;

                    //console.log("MOD:" + this.MOD_YN);
                }
            }
        }, 
        "cnslDtl.CNSL_TY_LCLS": function(nVal, oVal) {
            if(nVal.CNSL_TY_LCLS != oVal.CNSL_TY_LCLS) {
                this.MOD_YN = true;
            }
        }
    },
    methods: {
        openP101102(item){

            let agentId = this.userInfo.USER_ID;
            let startDt = item.CNSL_BDATE.replaceAll("-","");
            let endDate = item.CNSL_EDATE.replaceAll("-","");
            let callKey = item.RDWT_ID;
            let saveFlg = "0";    // 0:파일저장불가 1:파일저장가능

            if(this.mixin_isEmpty(startDt)) {
                this.common_alert(`녹취기간 정보가 없습니다.`, 'info');
                return false;
            }

            // if(this.mixin_isEmpty(callKey)) {
            //     this.common_alert(`녹취번호가 없습니다.`, 'info');
            //     return false;
            // }

            // 당일건만 청취
            if(startDt != moment().format("YYYYMMDD") && this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N') {
                this.common_alert(`당일건만 조회 가능합니다.`, 'info');
                return false;
            }

            // 상담사일경우
            if(this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N') {
                if(item.CNSL_ID != agentId) {
                    this.common_alert(`본인의 상담건만 조회 가능합니다.`, 'info');
                    return false;
                }
            } else {
                // 관리자는 소속부서만 청취가능
                if(item.DEPT_CD != this.geUserAttrA) {
                    this.common_alert(`본인의 소속부서건만 조회 가능합니다.`, 'info');
                    return false;
                }
            }
            
            
            //console.log(`openP101102 call item : ${JSON.stringify(item)}`);
            //popup token 처리
            this.getTalkHistList(item.CNSL_HIST_NO);
            this.$openWindow('P101102','P101102','500','800');
        },        

        //상담이력 조회
        getTalkHistList(key){
            apiChat.post("api/chat/main/cnslt-hist/chat-inqire/"+key,   //api url입력
            {
                ASP_NEWCUST_KEY: this.ASP_CUST_KEY,
                ASP_CUST_KEY: this.ASP_CUST_KEY,
                ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                action:'api/chat/main/cnslt-hist/chat-inqire/'+key,
                method:'post'
            },
            {
                head: {
            }})
            .then((response) => {
                //console.log("상담이력<<<<>>>>",response.data.data);
                if(Array.isArray(response.data.data)){
                for (var i = 0; i < response.data.data.length; i++) {
                    // response.data.data[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\\n").replace(/\\/g, "");
                    response.data.data[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\n", "\n");
                    response.data.data[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\", "");
                    response.data.data[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\n").replace(/\\/g, "");            
                }
                }
                localStorage['CHAT_DETAIL_LIST'] = JSON.stringify(response.data.data);

                //console.log("ChatList:::");
                //console.log(JSON.parse(localStorage['CHAT_DETAIL_LIST']));
                //this.$forceUpdate();
            })
            .catch((err) => {
                alert(err);
            })
        },       

        async callCommonCode(){
            let codeName = ['CRS000','CRS008', 'CS01', 'CS41', 'DS38'];
            this.common_code = await this.mixin_common_code_get_all(codeName);
            if(this.preDate) this.preDate = await this.mixin_getSvrPrevDate("%Y%m%d");

            this.dates[0] = this.mixin_dateFormat(this.preDate.TO_DAY);
            this.dates[1] = this.mixin_dateFormat(this.preDate.TO_DAY);

            this.cdCRS000 = this.mixin_common_code_get(this.common_code, 'CRS000');
            this.codeDS38 = this.mixin_common_code_get(this.common_code, 'DS38');

            if(this.dates[0].length >= 8) {
                let frDate = this.dates[0];
                this.dates[0] = this.mixin_dateFormat(frDate);
                this.from = this.dates[0];
                this.startDate(this.dates[0]);
                this.cnslInfo.CNSL_BEGIN_DATE = this.dates[0];
                this.cnslInfo.CNSL_END_DATE  = this.dates[1];

            }

            this.initData();
            //this.getAttrList();
        },

        async initData() {

            //this.callCommonCode()
            this.isLoading = false;
            this.isLoading2 = false;
            this.cnslInfo = {
                CONTT_CD: "",
                CHNN_CD: "",
                CUS_LEVEL_CD:"",
                CALL_TIME: 0
            };

            this.ATTR_A = "";
            this.ATTR_B = "";
            this.cnslInfo.CNSL_USID = "";
            this.MOD_YN = false;

            let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];

            this.userInfo.USER_ATTR_A = get_user_role.USER_ATTR_A;
            this.userInfo.USER_ATTR_B = get_user_role.USER_ATTR_B;
            this.userInfo.USER_ID = get_user_role.userId;

            this.setUserData();

            this.cnslDtl = {
                CNSL_HIST_NO: "",
                CNSL_TY_LCLS: "",
                CNSL_TY_MDCLS: "",
                CNSL_TY_SCLS: "",
                CNSL_CNTN: ""
            };

            this.MOD_YN = false;  // 수정여부
            if(!this.ASP_CUST_KEY) this.ASP_CUST_KEY = "001";
            this.changeCounselTypeKey(this.ASP_CUST_KEY, "*", "0");
        },
        getList(loadOptions) {
            if(this.mixin_isEmpty(this.from) || this.mixin_isEmpty(this.to)){
                this.common_alert("상담일자는 필수입니다.", "error");
                return false
            }
            if(!this.checkDate()) return false;
            this.isLoading = true;
            this.cnslDtl = {
                CNSL_HIST_NO: "",
                CNSL_TY_LCLS: "",
                CNSL_TY_MDCLS: "",
                CNSL_TY_SCLS: "",
                CNSL_CNTN: ""
            };

            this.dataTableOptions.load = this.selectCnslHst;

            if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();

            // this.getExcelList()
        },
        checkDate() {
            if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(1, 'months').format('YYYY-MM-DD'))) {
                this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
                return false;
            } 

            return true;
        },
        getExcelList(loadOptions) {
            console.log("getExcelListgetExcelListgetExcelListgetExcelListgetExcelList")
            if(this.mixin_isEmpty(this.from) || this.mixin_isEmpty(this.to)){
                this.common_alert("상담일자는 필수입니다.", "error");
                return false
            }
            this.isLoading2 = true;
            this.cnslDtl = {
                CNSL_HIST_NO: "",
                CNSL_TY_LCLS: "",
                CNSL_TY_MDCLS: "",
                CNSL_TY_SCLS: "",
                CNSL_CNTN: ""
            };

            this.dataTableOptionsExcel.load = this.selectCnslHstExcel;

            //console.log('제발')
            // this.downloadExcel('cnslHistExcelTable', '상담이력조회', '상담이력목록')                

            if (this.dataTableOptionsExcel.page > 1) this.dataTableOptionsExcel.page = 1;
            else this.dataTableInstance.loadData();

            
        },        
        selectCnslHst: async function (loadOptions) {
            // header 세팅
            let requestData = {
                headers: {
                ASYNC: false,
                METHOD: "list",
                SERVICE: "phone.history.cnsl-list",
                TYPE: "BIZ_SERVICE",
                ROW_CNT: loadOptions.itemsPerPage,
                PAGES_CNT: loadOptions.page,
                URL: "/api/phone/history/cnsl/list"
                },
                sendData:  this.cnslInfo,
            };
            // console.log("selectCnslHst loadOptions : ", loadOptions)

            const table = this.$el.children[2].children[1].children[0].children[0];
            table.scrollTop = 0;

            if(this.mixin_isEmpty(this.ASP_CUST_KEY)) this.ASP_CUST_KEY = "001";

            requestData.sendData["CNSL_BEGIN_DATE"] = this.from.replaceAll("-", "");
            if(this.mixin_isEmpty(this.to)) {
                requestData.sendData["CNSL_END_DATE"] = "";
            } else {
                requestData.sendData["CNSL_END_DATE"] = this.to.replaceAll("-", "");
            }   
            requestData.sendData["ASP_CUST_KEY"]  = this.ASP_CUST_KEY;
            requestData.sendData["DEPT_CD"] = this.ATTR_A;
            requestData.sendData["TEAM_CD"] = this.ATTR_B;
            requestData.sendData["CNSL_USID"] = this.CNSL_ID;

            if (requestData.sendData['SEND_MAN_NO']) {
                requestData.sendData['SEND_MAN_NO'] = this.cnslInfo.SEND_MAN_NO.replace(/\-/g, '');
            }

            if (loadOptions.sortBy && loadOptions.sortBy.length > 0) {
                requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
                if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0) {
                    requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
                }
            } else {
                requestData.sendData.SORT_DRCT = "desc";
                requestData.sendData.SORT_ORDR = "BEGIN_DTTM";
            }
            return this.common_postCall(requestData).then(response => {
                this.gridCnslHistItems = [];
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                if (!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                    if(!this.mixin_isEmpty(response.DATA) && response.DATA.length > 0) {
                        for(let d of response.DATA) {
                            for(let k of Object.keys(d)) {
                                if(k === 'CNSL_BDATE') d[k] = this.mixin_dateFormat(d[k])
                                else if(k === 'CNSL_BEGIN_TM') d[k] = this.mixin_timeFormat(d[k])
                                else if(k === 'CNSL_END_TM')   d[k] = this.mixin_timeFormat(d[k])
                                else if(k === 'ACCT_NO')       d[k] = this.mixin_accNoFormat(d[k])
                                else if(k === 'SEND_MAN_NO')   d[k] = this.mixin_newPhoneFormat(d[k])
                                else if(k === 'CUST_NO')  { 
                                    if(d[k].length > 8 || d[k] == '9999999999999') { 
                                        d['CL_CID'] = d[k];
                                        d[k] = '';
                                    }
                                }
                                else if(k === 'CNSL_CNTN') {
                                  d[k] = this.restoreXSS2(d[k]);
                                  d[k] = d[k].replaceAll("<BR>", "\r");
                                }
                                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                            }
                            d['IVR_IN_PATH'] = "";
                            this.gridCnslHistItems.push(d)
                        }
                    }
                }
                // row.select(true);
                this.isLoading = false;

                this.selectCnslHstExcel(loadOptions);

                return {
                    data: this.gridCnslHistItems,
                    totalCount: header.TOT_COUNT,
                };
                this.common_alert("정상 처리되었습니다.", "done");
            });
        },

        selectCnslHstExcel: async function (loadOptions) {
            this.isLoading2 = true;

            // console.log("selectCnslHstExcel in")
            // header 세팅
            let requestData = {
                headers: {
                ASYNC: false,
                METHOD: "list",
                SERVICE: "phone.history.cnsl-list",
                TYPE: "BIZ_SERVICE",
                ROW_CNT: 10000000000,
                PAGES_CNT: 1,
                URL: "/api/phone/history/cnsl/list"
                },
                sendData:  this.cnslInfo,
            };
            //console.log("loadOptions : ", loadOptions)

            const table = this.$el.children[2].children[1].children[0].children[0];
            table.scrollTop = 0;

            if(this.mixin_isEmpty(this.ASP_CUST_KEY)) this.ASP_CUST_KEY = "001";

            requestData.sendData["CNSL_BEGIN_DATE"] = this.from.replaceAll("-", "");
            if(this.mixin_isEmpty(this.to)) {
                requestData.sendData["CNSL_END_DATE"] = "";
            } else {
                requestData.sendData["CNSL_END_DATE"] = this.to.replaceAll("-", "");
            }   
            requestData.sendData["ASP_CUST_KEY"]  = this.ASP_CUST_KEY;
            requestData.sendData["DEPT_CD"] = this.ATTR_A;
            requestData.sendData["TEAM_CD"] = this.ATTR_B;
            requestData.sendData["CNSL_USID"] = this.CNSL_ID;

            if (requestData.sendData['SEND_MAN_NO']) {
                requestData.sendData['SEND_MAN_NO'] = this.cnslInfo.SEND_MAN_NO.replace(/\-/g, '');
            }

            if (loadOptions.sortBy && loadOptions.sortBy.length > 0) {
                requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
                if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0) {
                    requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
                }
            } else {
                requestData.sendData.SORT_DRCT = "desc";
                requestData.sendData.SORT_ORDR = "BEGIN_DTTM";
            }
            return this.common_postCall(requestData).then(response => {
                this.gridCnslHistExcelItems = [];
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                if (!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                    if(!this.mixin_isEmpty(response.DATA) && response.DATA.length > 0) {
                        for(let d of response.DATA) {
                            for(let k of Object.keys(d)) {
                                if(k === 'CNSL_BDATE') d[k] = this.mixin_dateFormat(d[k])
                                else if(k === 'CNSL_BEGIN_TM') d[k] = this.mixin_timeFormat(d[k])
                                else if(k === 'CNSL_END_TM')   d[k] = this.mixin_timeFormat(d[k])
                                else if(k === 'ACCT_NO')       d[k] = this.mixin_accNoFormat(d[k])
                                else if(k === 'SEND_MAN_NO')   d[k] = this.mixin_newPhoneFormat(d[k])
                                else if(k === 'CUST_NO')  { 
                                    if(d[k].length > 8 || d[k] == '9999999999999') { 
                                        d['CL_CID'] = d[k];
                                        d[k] = '';
                                    }
                                }
                                else if(k === 'CNSL_CNTN') {
                                  d[k] = this.restoreXSS2(d[k]);
                                  d[k] = d[k].replaceAll("<BR>", "\r");
                                }
                                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                            }
                            d['IVR_IN_PATH'] = "";
                            this.gridCnslHistExcelItems.push(d)
                        }
                    }
                }
                // row.select(true);
                this.isLoading2 = false;
                //console.log("this.gridCnslHistExcelItems : ",this.gridCnslHistExcelItems)
                this.dataTableOptionsExcel.items = this.gridCnslHistExcelItems
                // this.downloadExcel('cnslHistExcelTable', '상담이력조회', '상담이력목록')                
                // return {                    
                //     data: this.gridCnslHistExcelItems,
                //     totalCount: header.TOT_COUNT,
                // };
                // this.common_alert("정상 처리되었습니다.", "done");
            });
        },        
        /* 코드명 가져오기 */
        getCodeNm(sCd, pVal) {
            let cdNm = "";
            api
                .post(
                "/api/code/common/code-book/selectCodeNm", //api url입력
                {
                    GROUP_CD: sCd,
                    CODE: pVal,
                    USE_YN: "Y"
                },
                {
                    head: {
                    SERVICE: "api.palette-main.auth",
                    METHOD: "inqire",
                    TYPE: "BIZ_SERVICE",
                    },
                }
                )
                .then((response) => {
                    if (response.data.HEADER.COUNT == 0) {
                        let errMsg = response.data.HEADER.ERROR_MSG;
                        errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
                        //console.log("getCodeNm:[" + errMsg +"]");
                        return false;
                    }
                    cdNm = response.data.DATA[0].CD_NM;
                    if(sCd == "IVR04") {
                        this.cnslDtl.IVR_IN_PATH = cdNm;
                    }
                })
                .catch((err) => {
                    alert(err);
                });
            return cdNm;
        },
        /* 상담이력정보 저장 */
        saveCnslHist: async function() {
            if(this.mixin_isEmpty(this.cnslDtl.CNSL_HIST_NO)) {
                this.common_alert(`상담이력번호가 없습니다. 선택 후 저장 하십시오.`, 'info');
                return false;
            }

            let userId = this.$store.getters["userStore/GE_USER_ID"];

            if(userId != this.cnslDtl.CNSL_ID) {
                this.common_alert(`다른 직원이 입력한 상담 정보는 \n수정 할 수 없습니다.`, 'info');
                return false;
            }

            if(this.CURR_DT != this.cnslDtl.CNSL_BDATE) {
                this.common_alert(`당일 입력한 상담 정보만 \n수정 가능 합니다.`, 'info');
                return false;
            }

            if(this.mixin_isEmpty(this.cnslDtl.CNSL_TY_LCLS)) {
                this.common_alert(`상담유형(대) 선택 해주세요.`, 'info');
                return false;
            }

            if(this.mixin_isEmpty(this.cnslDtl.CNSL_TY_MDCLS)) {
                this.common_alert(`상담유형(중) 선택 해주세요.`, 'info');
                return false;
            }

            let ls_one_char = "";
            let re = /[.,<>/?;:{}~!@\#$%^&*\()\=+_']/gi;
            
            ls_one_char = this.cnslDtl.CNSL_CNTN.replace(re,"");

            if(this.mixin_isEmpty(ls_one_char) || ls_one_char.length < 1) {
                this.common_alert(`상담내용은 특수문제 제외 1자 이상 적으세요.`, 'info');
                return false;
            }

            if(!this.MOD_YN) {
                this.common_alert(`수정 사항이 없습니다.`, 'info');
                //return false;
            }

            this.common_confirm('상담이력을 저장 하시겠습니까?', async () => {
                let URLData = '/api/phone/main/cnslt-hist/modify';
                let HEADER_SERVICE = 'phone.main.cnslt-hist';
                let HEADER_METHOD = 'modify';
                let HEADER_TYPE = 'BIZ_SERVICE';

                this.cnslDtl.ASP_CUST_KEY = this.ASP_CUST_KEY;
                this.cnslDtl.CHNG_ID = this.USER_ID;

                await api
                  .post(URLData, this.cnslDtl, {
                    head: {
                        SERVICE: HEADER_SERVICE,
                        METHOD: HEADER_METHOD,
                        TYPE: HEADER_TYPE,
                        ASYNC: false,
                    },
                  })
                  .then((response) => {

                    if(response.data.HEADER.ERROR_FLAG) {
                        console.log("상담저장 ERR_MSG:["+response.data.HEADER.ERROR_MSG+"]");
                        this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
                        return false;
                    }

                    this.common_alert("성공적으로 저장했습니다.");
                    this.getList();
                  })
                  .catch((err) => {
                    this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
                    console.log(err);
                  });

                //상담저장 끝낸 후에는 콜백,스케줄을 다시 조회한다.
                //EventBus.$emit('check');

                }, null, () => {
                return;
                }, null, 'confirm');

        },
        changeCounselDetailTypeKey: async function (ASP_CUST_KEY, spst_counsel_typ_cd, data_type) {
            let CNSL = await this.mixin_counsel_type_code_div_get(
                "Y",
                ASP_CUST_KEY,
                "P",
                spst_counsel_typ_cd,
                "전체"
            );
            if (data_type === "0") {
                this.CNSL_TY_CODE_1 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_11 = [{ CD: "", CD_NM: "선택" }];
                this.CNSL_TY_CODE_22 = [{ CD: "", CD_NM: "선택" }];
                this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                this.cnslInfo.CNSL_TY_LCLS  = '';
                this.cnslInfo.CNSL_TY_MDCLS = '';
                this.cnslInfo.CNSL_TY_SCLS  = '';
                this.cnslDtl.CNSL_TY_LCLS  = '';
                this.cnslDtl.CNSL_TY_MDCLS = '';
                this.cnslDtl.CNSL_TY_SCLS  = '';

                this.CNSL_TY_CODE_11 = CNSL;
            } else if (data_type === "1") {
                if(this.cnslInfo.CNSL_TY_LCLS == "" || this.cnslInfo.CNSL_TY_LCLS == undefined) {
                    this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
                    this.cnslInfo.CNSL_TY_MDCLS = '';
                } else {
                    this.CNSL_TY_CODE_2 = CNSL;
                    // this.cnslInfo.CNSL_TY_MDCLS = spst_counsel_typ_cd + "01";
                    this.cnslInfo.CNSL_TY_MDCLS = this.CNSL_TY_CODE_2[1];
                    this.changeCounselDetailTypeKey(ASP_CUST_KEY, this.cnslInfo.CNSL_TY_MDCLS.CD, '2');
                }

                this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                this.cnslInfo.CNSL_TY_SCLS = '';
            } else if (data_type === "2") {
                if(this.cnslInfo.CNSL_TY_MDCLS == "" || this.cnslInfo.CNSL_TY_MDCLS == undefined) {
                    this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                    this.cnslInfo.CNSL_TY_SCLS = '';
                } else {
                    this.CNSL_TY_CODE_3 = CNSL;
                    this.cnslInfo.CNSL_TY_SCLS = this.CNSL_TY_CODE_3[1];
                }
            }  else if (data_type === "3") {
                if(this.cnslDtl.CNSL_TY_LCLS == "" || this.cnslDtl.CNSL_TY_LCLS == undefined) {
                    this.CNSL_TY_CODE_22 = [{ CD: "", CD_NM: "선택" }];
                    this.cnslDtl.CNSL_TY_MDCLS = '';
                } else {
                    this.CNSL_TY_CODE_22 = CNSL;
                    // this.cnslDtl.CNSL_TY_MDCLS = spst_counsel_typ_cd + "01";
                    this.cnslDtl.CNSL_TY_MDCLS = this.CNSL_TY_CODE_22[1].CD;
                    //console.log("this.cnslDtl.CNSL_TY_MDCLS   ", this.cnslDtl.CNSL_TY_MDCLS )
                    this.changeCounselDetailTypeKey(ASP_CUST_KEY, this.cnslDtl.CNSL_TY_MDCLS, '4');
                }

                this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                this.cnslDtl.CNSL_TY_SCLS = '';
            } else if (data_type === "4") {

                if(this.cnslDtl.CNSL_TY_MDCLS == "" || this.cnslDtl.CNSL_TY_MDCLS == undefined) {
                    this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                    this.cnslDtl.CNSL_TY_SCLS = '';
                } else {
                    this.CNSL_TY_CODE_32 = CNSL;
                    this.cnslDtl.CNSL_TY_SCLS = this.CNSL_TY_CODE_32[1].CD;
                }
            }
        },
        changeCounselTypeKey: async function (ASP_CUST_KEY, spst_counsel_typ_cd, data_type) {
            let CNSL = await this.mixin_counsel_type_code_div_get(
                "",
                ASP_CUST_KEY,
                "P",
                spst_counsel_typ_cd,
                "전체"
            );
            if (data_type === "0") {
                this.CNSL_TY_CODE_1 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                this.CNSL_TY_CODE_11 = [{ CD: "", CD_NM: "선택" }];
                this.CNSL_TY_CODE_22 = [{ CD: "", CD_NM: "선택" }];
                this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                this.cnslInfo.CNSL_TY_LCLS  = '';
                this.cnslInfo.CNSL_TY_MDCLS = '';
                this.cnslInfo.CNSL_TY_SCLS  = '';
                this.cnslDtl.CNSL_TY_LCLS  = '';
                this.cnslDtl.CNSL_TY_MDCLS = '';
                this.cnslDtl.CNSL_TY_SCLS  = '';

                this.CNSL_TY_CODE_1 = CNSL;
            } else if (data_type === "1") {
                if(this.cnslInfo.CNSL_TY_LCLS == "" || this.cnslInfo.CNSL_TY_LCLS == undefined) {
                    this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
                    this.cnslInfo.CNSL_TY_MDCLS = '';
                } else {
                    this.CNSL_TY_CODE_2 = CNSL;
                    this.cnslInfo.CNSL_TY_MDCLS = spst_counsel_typ_cd + "01";
                    this.changeCounselTypeKey(ASP_CUST_KEY, this.cnslInfo.CNSL_TY_MDCLS, '2');
                }

                this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                this.cnslInfo.CNSL_TY_SCLS = '';
            } else if (data_type === "2") {
                if(this.cnslInfo.CNSL_TY_MDCLS == "" || this.cnslInfo.CNSL_TY_MDCLS == undefined) {
                    this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                    this.cnslInfo.CNSL_TY_SCLS = '';
                } else {
                    this.CNSL_TY_CODE_3 = CNSL;
                    this.cnslInfo.CNSL_TY_SCLS = this.CNSL_TY_CODE_3[1].CD;
                }
            }  else if (data_type === "3") {
                if(this.cnslDtl.CNSL_TY_LCLS == "" || this.cnslDtl.CNSL_TY_LCLS == undefined) {
                    this.CNSL_TY_CODE_22 = [{ CD: "", CD_NM: "선택" }];
                    this.cnslDtl.CNSL_TY_MDCLS = '';
                } else {
                    this.CNSL_TY_CODE_22 = CNSL;
                    this.cnslDtl.CNSL_TY_MDCLS = spst_counsel_typ_cd + "01";
                    this.changeCounselTypeKey(ASP_CUST_KEY, this.cnslDtl.CNSL_TY_MDCLS.CD, '4');
                }

                this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                this.cnslDtl.CNSL_TY_SCLS = '';
            } else if (data_type === "4") {

                if(this.cnslDtl.CNSL_TY_MDCLS == "" || this.cnslDtl.CNSL_TY_MDCLS == undefined) {
                    this.CNSL_TY_CODE_32 = [{ CD: "", CD_NM: "선택" }];
                    this.cnslDtl.CNSL_TY_SCLS = '';
                } else {
                    this.CNSL_TY_CODE_32 = CNSL;
                    this.cnslDtl.CNSL_TY_SCLS = this.CNSL_TY_CODE_32[1];
                }
            }
        },
        fnLvlChng(val1) {
            this.cnslInfo.CUS_LEVEL = "";
            for(let item of this.codeDS38) {
                if(val1 == item.value) {
                    this.cnslInfo.CUS_LEVEL = item.text;
                }
            }
        },
        changeDate(type) {
            if(type == 'start') {
                this.from = this.dates[0];
            } else if (type == 'end') {
                this.to = this.dates[1];
            }
        },
        startDate(e) {
            this.from = e;
        },
        endDate(e) {
            this.to = e;
        },
        fromOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        toOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        onClickRow: function (item, row) {
            if (this.selectedRow != "") {
                this.activeRow(this.selectedRow, false);
                this.selectedRow = [];
            }
            this.MOD_YN = false;
            this.activeRow(row, true);
            this.setCnslDtlInfo(item);
        },

        sendToOpener(obj) {
            if (opener == null) {
                return;
            }
 
            let sendStr = JSON.stringify(obj);
            window.opener.postMessage(sendStr, '*');
        },

        onDblClickRow: function (e, { item }) {

            // if(this.geCallStt == "A_SETACW" && this.geCnslSaveYn == "N") {
            //     this.common_alert(`상담이력 저장이 필요합니다.`, 'info');
            //     return false;
            // } else if (this.geCallStt == 'TALKING') {
            //     this.common_alert(`통화중입니다.`, 'info');
            //     return false;
            // }

            //console.log("item[" + JSON.stringify(item) + "]");
            let param = {
                SRCH_ACCOUNT : item.ACCT_NO.replaceAll("-", ""),
                CUST_NO : item.CUST_NO,
                CLNT_NO : item.CUST_NO,
            }
            let sendParam = {
                message:  "E010201",
                Val1: param,
            };
            this.$emit('mvMainTab', sendParam);

            let acTabs = this.$store.getters["commonStore/GE_COMMON_TAB"];
            let aleady = false; //기존에 있는 탭인지 여부
            for(let i = 0; i < acTabs.length; i++) {
                if(acTabs[i].id === "E010101") {
                    //console.log("tab[" + acTabs[i].id + "]");
                    aleady = true;
                    break;
                }
            }
            if(aleady) {
                if(!this.mixin_isEmpty(this.geCustInfo.CLNT_NO) ||  !this.mixin_isEmpty(this.geCustInfo.SRCH_ACCOUNT)) {
                    eventBus.setCnslMode('init');
                }
                this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
                    id: "E010101",
                    title: "콜상담 메인",
                    link: "/E010101"
                });
                let custInfo = {};
                if(!this.mixin_isEmpty(item.ACCT_NO)) {
                    custInfo.SRCH_ACCOUNT = item.ACCT_NO.trim().replaceAll("-","");
                }
                custInfo.CUST_NO      = item.CUST_NO;
                custInfo.CLNT_NO      = item.CUST_NO;
                custInfo.SRCH_CL_CID  = item.CL_CID;
                if(item.CUST_NO == "00000000") custInfo.SRCH_CL_CID = "9999999999999";
                //custInfo.CNSL_HIST_NO = item.CNSL_HIST_NO;
                this.$store.commit("callStore/MU_CUST_INFO", custInfo);
                
                //console.log(">>>>>>>>>>"+JSON.stringify(item));

                if(!this.mixin_isEmpty(item.ACCT_NO)) {
                    eventBus.setCnslMode('search');    
                } else if(!this.mixin_isEmpty(item.CL_CID) || item.CUST_NO == "00000000") {
                    eventBus.setCnslMode('search2');
                } else {
                    // 고객번호로 조회
                    eventBus.setCnslMode('search3');
                }
                return;
            }

            let custInfo = {};
            custInfo.SRCH_ACCOUNT = item.ACCT_NO;
            custInfo.CUST_NO      = item.CUST_NO;
            custInfo.SRCH_CL_CID  = item.CL_CID;
            custInfo.CNSL_HIST_NO = item.CNSL_HIST_NO;
            this.$store.commit("callStore/MU_CUST_INFO", custInfo);

            // let custTmp = this.$store.getters["callStore/GE_CUST_INFO"];
            // console.log("CUST ADDR!!!" + JSON.stringify(custTmp));
            // 콜상담 메인 탭 오픈
            this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
                id: "E010101",
                title: "콜상담 메인",
                default: "N",
                tabIcon: "",
                link: "/E010101"
            });
        },
        /* 녹취서버 청취 WebPlay */
        openRdwtWebPlay(item) {
            let agentId = this.userInfo.USER_ID;
            let startDt = item.CNSL_BDATE.replaceAll("-","");
            let endDate = item.CNSL_EDATE.replaceAll("-","");
            let callKey = item.RDWT_ID;
            let saveFlg = "0";    // 0:파일저장불가 1:파일저장가능

            if(this.mixin_isEmpty(startDt)) {
                this.common_alert(`녹취기간 정보가 없습니다.`, 'info');
                return false;
            }

            if(this.mixin_isEmpty(callKey)) {
                this.common_alert(`녹취번호가 없습니다.`, 'info');
                return false;
            }

            // 당일건만 청취
            if(startDt != moment().format("YYYYMMDD")) {
                this.common_alert(`당일건만 청취 가능합니다.`, 'info');
                return false;
            }

            // 상담사일경우
            if(this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N') {
                if(item.CNSL_ID != agentId) {
                    this.common_alert(`본인의 상담건만 청취 가능합니다.`, 'info');
                    return false;
                }
            } else {
                // 관리자는 소속부서만 청취가능
                if(item.DEPT_CD != this.geUserAttrA) {
                    this.common_alert(`본인의 소속부서건만 청취 가능합니다.`, 'info');
                    return false;
                }
            }
            var VUE_APP_RDWP_SERVER_URL = process.env.VUE_APP_RDWP_SERVER_URL
            var VUE_APP_RDWP_SERVER_PORT = process.env.VUE_APP_RDWP_SERVER_PORT               

            //this.$openWindow(RDWP_SERVER_URL1 + "?agentId="+ agentId + "&startDate=" + startDt + "&endDate=" + endDate + "&callKey=" + callKey + "&SaveFlag=" + saveFlg, 'WebPlay', 'width=780,height=360');
            this.$openWindow(VUE_APP_RDWP_SERVER_URL + ":" + VUE_APP_RDWP_SERVER_PORT + "/provider/key/play?agentId="+ agentId + "&startDate=" + startDt + "&endDate=" + endDate + "&callKey=" + callKey + "&SaveFlag=" + saveFlg, 'WebPlay', '770','335');
        },
        setCnslDtlInfo: async function(item) {
            //console.log("itmemmmentmnjsakjgsank", item)
            this.CNSL_TY_CODE_11 = await this.mixin_counsel_type_code_div_get(
                "Y",
                this.ASP_CUST_KEY,
                "P",
                "*",
                "전체"
            );
            // 상담유형 중
            this.CNSL_TY_CODE_22 = await this.mixin_counsel_type_code_div_get(
                "Y",
                this.ASP_CUST_KEY,
                "P",
                item.CNSL_TY_LCLS,
                "전체"
            );

            if(item.CNSL_TY_SCLS) {
                this.CNSL_TY_CODE_32 = await this.mixin_counsel_type_code_div_get(
                    "Y",
                    this.ASP_CUST_KEY,
                    "P",
                    item.CNSL_TY_MDCLS,
                    "전체"
                );
            }
            this.cnslDtl.IVR_IN_PATH = "";
            let ivrInPath = "";
            if(!this.mixin_isEmpty(item.IVR_SERV_NO)) {
                if (item.IVR_SERV_NO.length == 5) {
                    ivrInPath = this.getCodeNm("IVR04", item.IVR_SERV_NO);
                } else {
                    ivrInPath = item.IVR_SERV_NO;
                }
            }
            this.cnslDtl = item;

            for(let item of this.cdCRS000) {
                if(this.cnslDtl.TEL_RSLT_CD == item.value) this.cnslDtl.TEL_RSLT_CNM = item.text;
            }
            this.cnslDtl.IVR_IN_PATH = ivrInPath;
            this.cnslDtl.CNSL_CNTN   = this.cnslDtl.CNSL_CNTN.replaceAll("<BR>", "\n");
        },
        activeRow(row, value) {
            this.selectedRow = row;
            row.select(Boolean(value));
            row.isSelected = Boolean(value);
        },
        setUser(userData){
            //console.log("#formData:"+JSON.stringify(userData));
            let user = {
                USER_ID: this.userData.USER_ID,
                USER_NICK:this.userData.USER_NICK,
                USER_NM:this.userData.USER_NM,
            };
            this.userInfo = user;
        },
        async setUserData(){
            //console.log("####"+JSON.stringify(this.userInfo));
            this.formData.USER_ID = this.userInfo.USER_ID;
            this.formData.USER_NM = this.userInfo.USER_NM;

            this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', this.userInfo.USER_ATTR_A);
            this.CNSL_COMBO = await this.mixin_get_user_by_attr(this.userInfo.USER_ATTR_A, this.userInfo.USER_ATTR_B);

            this.ATTR_A  = this.userInfo.USER_ATTR_A;
            this.ATTR_B  = this.userInfo.USER_ATTR_B;
            this.CNSL_ID = this.userInfo.USER_ID;
        },
        async changeDept(attrA){
            //console.log("####changeDept"+attrA);
            if(this.mixin_isEmpty(attrA)) {
                this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
                this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
            } else {
                this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', attrA);
            }
            this.CNSL_ID = '';
            this.ATTR_B = '';
        },
        async changeUser(attrA, attrB){
            if(this.mixin_isEmpty(attrB)) {
                this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
            } else {
                this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
            }
            this.CNSL_ID = '';
        },
        //사용자 소속 콤보박스 생성
        getAttrDiv(attr) {
            api.post("api/palette/common/get-attr-div/list",   //api url입력
            {
                ATTR_CD: attr,
                ATTR_DIV_CD: '',
            },
            {head: {
                "SERVICE" : "palette.common.get-attr-div",
                "METHOD" : "list",
                "TYPE" : "BIZ_SERVICE",
            }})
            .then((response) => {
                if (attr == 'A'){
                this.DEPT_COMBO = [...this.DEPT_COMBO, ...response.data.DATA];
                } else if (attr == "B") {
                this.TEAM_COMBO = [...this.TEAM_COMBO, ...response.data.DATA];
                }
            })
            .catch((err) => {
                alert(err);
            })
        },
        callApiTest: async function () {
            let params = {
                data: {
                    trxcd: "01",
                    variable0: "a1234sdfasdfsdfd",
                    variable1: "01022223333",
                    variable2: "20160658401",
                    variable3: ""
                }
            };
            let userId = this.$store.getters['userStore/GE_USER_ID']

            let URLData = "/call/phnpad/"+userId;
            let HEADER_SERVICE = '/call/phnpad';
            let HEADER_METHOD = 'inqire';
            let HEADER_TYPE = 'BIZ_SERVICE';

            await apiCall.callPost(URLData, params);
            // head: {
            //     SERVICE: HEADER_SERVICE,
            //     METHOD: HEADER_METHOD,
            //     TYPE: HEADER_TYPE,
            //     ASYNC: false,
            // },
            // .then((response) => {
            //     this.repeatList = response.data.DATA;
            // })
            // .catch((err) => {

            //     this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
            //     console.log(err);
            // });

        },
        //Table row 클릭이벤트
        rowClick1(item, row) {
            if (item != null) {
                this.activeRow(row, true);
            }
        },
        activeRow(row, value) {
            this.selectedRow = row;
            row.select(Boolean(value));
            row.isSelected = Boolean(value);
        },
        downloadExcel(tableId, fileNm, sheetNm){

           //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "n"; 
            exceltypeArr[1] = "s"; 
            exceltypeArr[2] = "t"; 
            exceltypeArr[3] = "t"; 
            exceltypeArr[4] = "t"; 
            exceltypeArr[5] = "s"; 
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s";
            exceltypeArr[9] = "s"; 
            exceltypeArr[10] = "s"; 
            exceltypeArr[11] = "s";
            exceltypeArr[12] = "s"; 
            exceltypeArr[13] = "s"; 
            exceltypeArr[14] = "s";
            exceltypeArr[15] = "s";
            exceltypeArr[16] = "s"; 
            exceltypeArr[17] = "s";
 
            //엑셀다운로드
            this.table_id   = tableId;
            this.file_name  = fileNm;
            this.sheet_name = sheetNm;
            this.mixin_common_exportExcel(exceltypeArr);
        },
        openSave(){
            this.$openWindow('T101201','T101201','700','480');
        },
        showDialog(type) {
            //팝업 파라미터 설정
            this.popupParams;
            this[`dialog${type}`] = true;
        },
        hideDialog(type) {
            this[`dialog${type}`] = false;
        },
        submitDialog(type){
            //console.log('완료');
            this[`dialog${type}`] = false
        },
        async getAttrList() {
            this.DEPT_COMBO = await this.mixin_get_attr_by_attr('****', '');
            this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
            this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];

            this.ATTR_A = '';
            this.ATTR_B = '';
            this.CNSL_ID = '';
        }
    }
};
</script>

<style></style>  